//color
$black:#040504;
$grey-dark: #333333;
$grey-light:#949494;
$white:#ffffff;
$blue-dark:#001B3A;
$gold:#b38b4d;
$gold-pale: #E6BE8A;
//font-size
$font-heading-l: 4rem;
$font-heading-m: 3rem;
$font-heading-s: 2rem;

$font-p-s: 1.5rem;
$font-p: 1.8rem;

$navigation-width: 30%;
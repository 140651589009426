*, *::after, *::before{
	margin: 0;
	padding: 0;
    box-sizing: inherit;
    
}

a {
    text-decoration: none;
}

html {

    font-size: 62.5%; 
    font-family: 'Roboto',Arial, Helvetica, sans-serif;
    
    @include respond(phone-s) { // width < 375?
        font-size: 37.5%; //1 rem = 6px, 6/16 = 37.5%
    }
    @include respond(phone-l) { // width < 600?
        font-size: 43.75%; //1 rem = 7px, 7/16 = 43.75%
    }
    @include respond(tab-land) { // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) { // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }
    
    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

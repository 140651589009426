.navigation{
    &__container{
        position: fixed;
        top:0;
        left:0;
        width: $navigation-width;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: rgba($white,0.7);
        border-right: $grey-light 1px solid;
        z-index: 1000;

        @include respond(tab-port){
            flex-direction: row;
            border: none;
            width: 100%;
            height: auto;
        }
    }
    &__heading{
        width: 70%;
        font-size: $font-heading-s;
        letter-spacing: 3px;
        margin: 5rem auto;
        text-transform: uppercase;
        display: flex;
        @include respond(tab-port){
            width: 80%;
            margin: 2rem 3rem;
        }
        &__group{
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include respond(tab-port){
                width: auto;
                margin-right: 2rem;
            }
            @include respond(phone-l){
               margin-right: 0.5rem;
            }
            &--1{
                width: 100%;
                font-weight: 900;
                color: $gold;
                animation: moveInLeft 2s ease-in;
            }
            &--line{
                background-color: $gold;
                height: 2px;
                width: 120%;
                margin: 0.5rem 0;
                animation: displayComponent 4s ease-in;
                @include respond(tab-port){
                    display: none;
                }
            }
            &--2{
                animation: displayComponent 2s ease-in;
            }
        }
        &__logo{
            width: 30%;
            @include respond(tab-port){
                width: 25%;
            }
            &__link{
                width: 100%;
                display: flex;
                margin-left: 5%;
                cursor: pointer;
                @include respond(tab-port){
                    margin-left: 0;
                }
            }
            &__content{ 
                width: 100%;
                z-index: 100;
                animation: moveInLeft 2s ease-in;
                @include respond(tab-port){
                    width: 40%;
                }
                @include respond(phone-l){
                    width: 50px;height: 50px;
                }
                @include respond(phone-s){
                    width: 45px;height: 45px;
                }
            }
        }
    }
    &__bar{
        width: 100%;
        margin-top: 4rem;
        display: flex;
        flex-wrap: wrap;
        @include respond(tab-port){
            display: none;
        }
        &__link{

            &__container{
                width: 100%;
                margin-bottom: 2rem;
               
            }
            
            &,&:link, &:visited{
                font-size: $font-p;
                color: $grey-dark;
                display: flex;
                position: relative;
                margin-left: 25%;
                cursor: pointer;
            }
    
            &__active{
                color: $gold;
                margin-left: 0;
                font-size: $font-p;
                display: flex;
                &::before{
                    content: "";
                    height: 1px;
                    width: 25%;
                    background-color: $gold;
                    display: inline-block;
                    margin-right: 0.5rem;
                    align-self: center;
                }
            }
    
            &:hover{
                transform: translateY(-3px);
                color: $gold;
                transition: color 0.5s;
            }
            &:active, &:focus{
                color: $gold;
                margin-left: 0;
                &::before{
                    content: "";
                    height: 1px;
                    width: 25%;
                    background-color: $gold;
                    display: inline-block;
                    margin-right: 0.5rem;
                    align-self: center;
                }
            }
        }
    }

    &__burger{

        display: none;

        @include respond(tab-port){
            width: 20%;
            display: flex;
            justify-content: flex-end;
            margin-right: 2rem;
        }
        @include respond(phone-l){
            width: 40%;
        }

        &__bar{
            display: none;

            @include respond(tab-port){
                display: flex;
                margin-right: 2rem;
            }
            width:100vw;
            height: 100vh;
            transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            flex-direction: column;
            align-items: center;
            background-color: rgba($white, 0.7);
            animation: displayComponent 1s ease-in;
            background-image: linear-gradient(
                to right bottom,
                rgba($white, 0.2),
                rgba($white, 0.7)),
            url(../../assets/navigation-burger-bar.jpg);
            background-size: cover;
            padding-top: 15rem;

            &__link{

                &__container{
                    margin-bottom: 1rem;
                    
                }
                
                &,&:link, &:visited{
                    font-size: $font-heading-m;
                    color: $grey-dark;
                }
        
                &__active{
                    font-size: $font-heading-m;
                    color: $gold;
                }
                &:hover{
                    transform: translateY(-3px);
                    color: $gold;
                    transition: color 0.5s;
                }
            }
        }

        &__checkbox{
            visibility: hidden;
        }

        &__icon {
            position: relative;
            margin-top: 3.5rem;
            z-index: 3000;
            &,
            &::before,
            &::after {
                width: 3.5rem;
                height: 2px;
                background-color: $gold;
                display: inline-block;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                transition: all 0.5s;
            }

            &::before { top: -1rem; }
            &::after { top: 1rem; }
        }

        &__icon__cross{
            &::before{
                top: 0;
                transform: rotate(135deg);
            }
            &::after{
                top: 0;
                transform: rotate(-135deg);
            }
            background-color: transparent;
        }
    }
}

/*
        &__button:hover &__icon::before {
            top: -1rem;
        }

        &__button:hover &__icon::after {
            top: 1rem;
        }

        &__checkbox:checked + &__button &__icon {
            background-color: transparent;
        }

        &__checkbox:checked + &__button &__icon::before {
            top: 0;
            transform: rotate(135deg);
        }

        &__checkbox:checked + &__button &__icon::after {
            top: 0;
            transform: rotate(-135deg);
        }
*/
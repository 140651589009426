.footer{
    color: $grey-light;
    position: fixed;
    bottom: 2rem;
    left: 4rem;
    z-index: 1000;
    font-size: $font-p-s;
    font-weight: 400;
    letter-spacing:1px;
    line-height: 150%;
    &__link{
        color: $grey-light;
        &:hover{
            background-color:$black;
            color: $white;
            padding: 0.3rem;
        }
    }
}
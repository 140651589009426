.landingPage{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: relative;

  &__video {
    position: fixed;
    top:0;
    left: 0;
    min-width: 100%;
    min-height: 100vh;
    overflow: hidden;
    margin: 0;
    z-index: 1;
    filter: grayscale(100%) contrast(110%);
  }

  &__container {
    height: 100vh;
    width: 70%;
    position: fixed;
    overflow: hidden !important;
    top:0;
    left: 30%;
    z-index: 100;
    display: flex;
    justify-content: center;
    @include respond(tab-port){
      width: 100%;
      top: 20%;
      left: 0;
    }

    &--wrapper {
      margin-top: 10%;
      width: 65%;

      @include respond(tab-port){
        width: 80%;
      }
    }

    &__img{
      width: 100%;
      border-radius: 6px;
      z-index: 100;
      overflow: hidden !important;

      @include respond(tab-port){
        width: 0%;
        height: 0%;
      }

      &--item {
        width: 100%;
        height: 100%;
        filter: grayscale(40%) contrast(110%);
      }
    }
  }

  &__banner {
    margin-top: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 0px !important;
    border-radius: 6px;
    overflow: hidden !important;

    &--item {
      width: 100%;
      height: auto;
      border-radius: 6px;
      overflow: hidden !important;
      @include respond(tab-port){
        height: auto;
      }
    }
  }
}


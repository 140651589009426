.btn{
    border: none;

    &__slide{
        background-color: $gold;
        font-size: 1rem;
        color: $white;
        padding: 1rem;
        border-radius: 3px;
        &:hover{
            background-color: $grey-dark;
        }
    }
    &__downloadLink{
        &:link, &:visited{
            text-decoration: none;
            font-size: $font-p;
            color: $gold;
            
        }
        &:hover{
            background-color: $black;
            padding:1rem;
            color: $white;
        }
    }
    &__scrollToTop{
        background-color: $gold;
        font-size: 1.5rem;
        width: 1.5rem;
        height: 2rem;
        color: $gold;
        padding: 0.5rem;
        position: fixed;
        bottom: 14rem;
        right: 1rem;
    }
}
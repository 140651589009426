@keyframes displayComponent{
    0%{opacity: 0;}
    50%{opacity: 0.4;}
	100%{opacity: 1;}
}
@keyframes moveInLeft{
    0%{opacity: 0;transform: translateX(-2rem);}
    50%{opacity: 0.5;transform: translateX(2rem)}
	100%{opacity: 1;transform: translateX(0)}
}
@keyframes moveInBottom{
    0%{opacity: 0;transform: translateY(-2rem);}
    50%{opacity: 0.5;transform: translateY(2rem)}
	100%{opacity: 1;transform: translateY(0)}
}

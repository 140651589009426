
//Media Query Manager
/*
0-600px: phone;
600-900: Tablet portrait;
900-1200: Tablet landscape;
1200-1800: Our normal styles;
1800px +: Big desktop;
*/


@mixin respond($breakpoint) {
    @if $breakpoint == phone-s {
        @media only screen and (max-width: 23.44em) { @content };    //375px
    }
    @if $breakpoint == phone-l {
        @media only screen and (max-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };    //1800
    }
}
.u-text-gold{color:$gold;}
.u-center-text{text-align: center;}
.u-text-white{color: $white;}
.u-uppercase{text-transform: uppercase;}
.u-m-right-small{margin-right: .5rem;}
.u-m-left-small{margin-left: .5rem;}

.u-m-bottom-paragraph{margin-bottom: 3rem;}
.u-font-p {font-size: $font-p;}
.u-font-p-s {font-size: $font-p-s;}
.u-mtb-medium {margin: 2rem 0;}



.page{

    &__container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 5rem;
        animation: displayComponent 2s ease-in;
    }

    &__heading{
        width: 50%;
        text-align: center;
        margin: 6rem 0;
        margin-left: $navigation-width;
        animation: moveInLeft 1s ease-in;
        @include respond(tab-port){margin-left: 0;width: 80%;}
        &__agenda {
            @include respond(tab-port){margin-top: 22%;}
        }
    }

    &__section{
        width: 100%;
        margin-left: $navigation-width;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 3rem;
        @include respond(tab-port){margin-left: 0;}
        &__info{
            width: 70%;
            text-align: justify;
            @include respond(tab-port){width: 80%;}
        }
        &__video{
            width: 100%;
            margin: 0;
            filter: grayscale(100%) contrast(110%);

            @include respond(tab-port){
                min-height: 30vh; 
                background-attachment: scroll;
                background-position: center top;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                display: flex;
                justify-content: center;
            }

        }
    }

    &__agenda{

        &__event{
            margin-top: 2rem;
            display: grid;
            align-items: center;
            grid-template-columns: 0.5fr 1fr;

            @include respond(tab-port){grid-template-columns: 1fr 1fr;}
    
            &__date{
                max-width: 14rem;
                border-radius: 3px;
                height: 3rem;
                background-color: rgba($gold,0.8);
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 0.5rem 1rem;
                justify-content: center;
                
                &__content{
                    align-self: center;
                }
            }
            &__text{
                align-self: center;
                margin-left: 1rem;
                text-align: left;
            }
        }
    }
    
    &__activities{
        width: 70%;
        @include respond(tab-port){width: 85%;}
        &__item{
            width: 100%;
            filter: grayscale(40%) contrast(110%) sepia(20%);
        }

        &__caption{
            font-size: $font-p;
            color: $grey-dark;
            margin-bottom: 1rem;
            text-align: center;
        }
    
    }

    &__members{
        width: 100%;
        margin-left: $navigation-width;
        margin-bottom: 5rem;
        padding: 3rem;
        @include respond(tab-port){margin-left: 0;}
        &__photo__container{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

        }
        &__photo__card{
            width: 18%;
            display: flex;
            flex-direction: column;
            &:hover{
                transform: scale(1.1);
            }
            @include respond(tab-port){width: 45%;}
        }
        &__photo__content{
            width: 100%;
            border-radius: 4px;
            overflow: hidden;
            margin-bottom: 1rem;
            //filter: grayscale(10%) contrast(110%);
        }
    }

    &__sponser {
        &__img {
            margin: 3rem 0;
            display: flex;
            justify-content: center;
            &--item {
                width: 130px;
                cursor: pointer;
            }
        }
        &__sub-heading {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            font-weight: 400;
        }
    }
}

.parallax{

    width: 100%;
    min-height: 60vh; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    filter: grayscale(100%) contrast(130%);
    @include respond(tab-port){
        background-size: contain;
        background-position: center top; 
        min-height: 35vh; 
    }

    &__introduction{
        background-image: url("../../assets/intro-1.jpg");
        z-index: 1;
    }
    &__history{

        background-image: url("../../assets/history.jpg");
        display: flex;
        justify-content: flex-end;
        &::before{
            content: "Dodewaards mannenkoor. Foto genomen tussen 1945 en 1948.";
            font-size: $font-p;
            color: $white; 
            padding:0.5rem;
            margin: 2rem; 
            border-radius: 3px; 
            height: 2%;    
            text-shadow: 2px 2px $black;  
        }
        @include respond(tab-port){
            width: 100%;
            min-height: 50vh; 
            background-attachment: scroll;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

        }

    }
    &__agenda{
        background-image: url(../../assets/agenda.jpg);

    }
    &__activities{
        background-image: url("../../assets/activities/Optocht\ bevrijdingsdag\ 5-05-2015-2.jpg");
    }
    &__becomeMember{
        &--1{background-image: url("../../assets/cover-old.jpg");}
        &--2{background-image: url("../../assets/becomeMember-1.jpg");}
    }
    &__sponser{
        background-image: url("../../assets/sponser.jpg");
    }
    &__contact{
        background-image: url(../../assets/contact.jpg);
    }
    &__home {
        background-image: url(../../assets/cover-old.jpg);
    }
}
.heading{
    &--l{text-transform: uppercase; font-size: $font-heading-l;letter-spacing: 3px;font-weight: 400;}
    &--m{font-size: $font-heading-m;letter-spacing: 3px;font-weight: 800;}
    &--sm{font-size: $font-heading-s;letter-spacing: 3px;}
}
.paragraph{
    font-size: $font-p;
    line-height: 140%;
    &--light{
        color: $white;
    }
    &--dark{
        color: $grey-dark;
    }
    &--gold{
        color: $gold;
    }
    &--highlight{
        color: $gold;
        background-color: transparent;
    }
}
